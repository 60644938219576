export function formatDates(dateFns, data, dateFields) {
  if (!dateFields) {
    console.error('No date fields provided')
    return data
  }

  dateFields.forEach((field) => {
    const value = data[field]

    if (value === true) {
      data[field] = dateFns.format(new Date(), 'yyyy-MM-dd')
    } else if (value === false || value === null) {
      data[field] = null
    } else if (value instanceof Date || !isNaN(new Date(value))) {
      data[field] = dateFns.format(value, 'yyyy-MM-dd')
    } else {
      data[field] = null
    }
  })

  return data
}

export function replaceUndefinedWithNull(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      data[key] = null
    }
  })

  return data
}
