import { formatDates, replaceUndefinedWithNull } from '~/utils/dateFormatter'

const dateFields = [
  'due_on',
  'valid_from',
  'commission_expected_at',
  'commission_paid_at',
  'canceled_at',
  'repayment_repaid_at',
]
export const state = () => ({
  pensions: null,
})

export const actions = {
  async create({ commit }, { contactId, data }) {
    data = formatDates(this.$dateFns, data, dateFields)
    data = replaceUndefinedWithNull(data)

    const pension = await this.$axios.$post(
      `contacts/${contactId}/pensions`,
      data
    )
    return pension.data
  },

  async update({ dispatch }, { id, data }) {
    data = formatDates(this.$dateFns, data, dateFields)
    data = replaceUndefinedWithNull(data)

    const pension = await this.$axios.$patch(`/pensions/${id}`, data)

    return pension.data
  },

  async destroy({ commit }, { id }) {
    await this.$axios.$delete(`/pensions/${id}`)
  },

  async destroyDocument({ commit }, { pensionId, documentId }) {
    await this.$axios.$delete(`pensions/${pensionId}/documents/${documentId}`)
  },

  async destroyAllDocuments({ commit }, { pensionId }) {
    await this.$axios.$delete(`pensions/${pensionId}/documents/all`, {
      params: {
        mediaGroup: 'pension-documents',
      },
    })
  },
}

export const getters = {}
