import { encodeQueryParams } from '~/utils'
import { formatDates, replaceUndefinedWithNull } from '~/utils/dateFormatter'

export const state = () => ({
  contactOptions: [],
  contact: null,
})

export const actions = {
  save({ dispatch }, { data, id, customerId }) {
    let contact = {
      ...data,
      gender: data.gender?.value ?? null,
      phone: data.phone?.replace(/ /g, ''),
      phone_2: data.phone_2?.replace(/ /g, ''),
      mobile: data.mobile?.replace(/ /g, ''),
      is_us_citizen: data.is_us_citizen ? new Date() : null,
      has_immunity: data.has_immunity ? new Date() : null,
    }

    contact = formatDates(this.$dateFns, contact, [
      'birthdate',
      'is_us_citizen',
      'has_immunity',
      'marital_status_since',
    ])

    contact = replaceUndefinedWithNull(contact)

    if (id) {
      return dispatch('update', {
        id,
        data: contact,
      })
    }

    return dispatch('create', {
      customerId,
      data: contact,
    })
  },
  async create({ dispatch }, { customerId, data }) {
    const contact = await this.$axios.$post(
      `customers/${customerId}/contacts`,
      data
    )
    return contact.data
  },
  async update(store, { id, data }) {
    const contact = await this.$axios.$patch(`contacts/${id}`, data)
    return contact.data
  },
  async loadContactOptions({ commit }, filters) {
    const query = encodeQueryParams({
      page: 1,
      ...filters,
    })

    const { data } = await this.$axios.get(`contacts?${query}`)
    commit('setContactOptions', data.data)
  },
  async load({ commit }, id) {
    const { data } = await this.$axios.$get(`contacts/${id}`)
    commit('setContact', data)
  },
}

export const mutations = {
  setContactOptions(state, contacts) {
    state.contactOptions = contacts
  },
  setContact(state, contact) {
    state.contact = contact
  },
}

export const getters = {
  contactOptions: (state) =>
    state.contactOptions.map((c) => ({
      id: c.id,
      label: c.name,
      street: c.street,
      postcode: c.postcode,
      location: c.location,
    })),
  contact: (state) => state.contact,
}
