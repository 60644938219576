import { formatDates, replaceUndefinedWithNull } from '~/utils/dateFormatter'

const dateFields = [
  'due_on',
  'valid_from',
  'one_time_commission_paid_at',
  'one_time_commission_expected_at',
  'annual_commission_expected_at',
  'annual_commission_paid_at',
  'annual_commission_active_at',
  'canceled_at',
  'repayment_repaid_at',
]

export const state = () => ({
  objectInsurances: null,
})

export const actions = {
  async create({ commit }, { objectId, data }) {
    data = formatDates(this.$dateFns, data, dateFields)
    data = replaceUndefinedWithNull(data)

    const objectInsurance = await this.$axios.$post(
      `objects/${objectId}/object-insurances`,
      data
    )
    return objectInsurance.data
  },

  async update({ dispatch }, { id, data }) {
    data = formatDates(this.$dateFns, data, dateFields)
    data = replaceUndefinedWithNull(data)

    const objectInsurance = await this.$axios.$patch(
      `/object-insurances/${id}`,
      data
    )

    return objectInsurance.data
  },

  async destroy({ commit }, { id }) {
    await this.$axios.$delete(`/object-insurances/${id}`)
  },

  async destroyDocument({ commit }, { objectInsuranceId, documentId }) {
    await this.$axios.$delete(
      `object-insurances/${objectInsuranceId}/documents/${documentId}`
    )
  },

  async destroyAllDocuments({ commit }, { objectInsuranceId }) {
    await this.$axios.$delete(
      `object-insurances/${objectInsuranceId}/documents/all`,
      {
        params: {
          mediaGroup: 'object-insurance-documents',
        },
      }
    )
  },
}

export const getters = {}
